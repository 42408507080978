import {
	Box,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';
import Button from '../../components/Button/Button';
import { Download, VerticalAlignBottomOutlined } from '@mui/icons-material';
import useLocalStorage from '../../utils/localStorage';
import useApiService from '../../services/api.service';
import ShareReportsEndpoints from './ShareReportsEndpoints';
import { savePDF } from '@progress/kendo-react-pdf';
import React, { createElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/styles';
import pageTemplate from './pageTemplate';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import MultiSelectDropdown from '../../components/Dropdown/MultiSelectDropdown';
import {
	columns,
	defaultColumns,
	closed_won_pdf_column,
	pipeline_pdf_column,
	cmoclosedWonColumn,
	cmoPipelineColumn,
	accountPerformanceColumns,
} from './PerformancePDF';
import useToast from '../../components/Toast/hooks/useToast';
import Tooltip from '../../components/Tooltip/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DynamicTableEndPoint from '../../components/DynamicDataTable/DynamicTableEndpoint';
import { useLocation } from 'react-router-dom';
import usePdfModifier from './PdfModify';
import { useDispatch, useSelector } from 'react-redux';
import {
	downloadPdf,
	setPdfColumnsAction,
} from '../../actions/cmoDashboardActions';
import { ignoreOrderCompare } from '../../constants/filterData';
import {
	VARIABLE_INSIGHTS_CHANNEL_PAID_CAMPAIGN_TRACKER,
	VARIABLE_INSIGHTS_CHANNEL_PATH_ANALYSIS,
	VARIABLE_INSIGHTS_CHANNEL_SEARCH_CONSOLE_REPORT,
	VARIABLE_INSIGHTS_CHANNEL_TOP_CONVERSION,
	VARIABLE_INSIGHTS_CMO_COHORT,
	VARIABLE_INSIGHTS_CMO_DASHBOARD,
	VARIABLE_INSIGHTS_MO_METRICS_HUB,
	VARIABLE_INSIGHTS_MO_PATH_LENGTH,
	VARIABLE_INSIGHTS_TOTAL_SPENT,
	VARIABLE_INSIGHTS_CHANNEL_HUBSPOT_REPORT,
	VARIABLE_INSIGHTS_CMO_PERFORMANCE_REPORT,
	VARIABLE_INSIGHTS_MO_MODEL_COMPARISON,
	VARIABLE_INSIGHTS_CHANNEL_PAID_MEDIA_TRACKER,
	VARIABLE_INSIGHTS_ABM_COMPARISON_DASHBOARD,
} from '../../constants/staticPathVariables';
import { useSidebar } from '../../utils/SideBarContext';

function ShareThroughDownload({
	dateRange,
	filters,
	loader,
	name,
	id,
	ref,
	shareReportsRequest,
	closePopup,
	downloadPDFClassName,
	csvDownloadFunc,
	tabValue,
}: any) {
	const includeCSV = [
		// 'CMO',
		'Performance',
		'Channel Performance',
		'Paid Campaign Tracker',
		// 'performance_report',
		// 'paid_campaign_tracker',
		'Paid Media Tracker',
		'SEO',
		'Email Marketing',
		// 'Total Spend',
	];
	const location = useLocation();
	const { setShowSidebar } = useSidebar();
	const { pathname, search } = location;
	const dispatch: any = useDispatch();
	const theme: any = useTheme;
	const toaster: any = useToast();
	const selcetedColumnsforpct: any = useLocalStorage.getItem('pctSavedcolumns');
	const tablePayload: any = useLocalStorage.getItem('TablePayloadforCSV');
	const count: any = useLocalStorage.getItem('count');
	const APIService: any = useApiService();
	const userData: any = useLocalStorage.getItem('userData');
	const [values, setValues] = React.useState('CSV');

	const [modifications, setModifications]: any = useState({
		hideSidebar: 'digg_sidebar', // ID of the sidebar to hide
		backgroundColor: '#FFFFFF', // Background color to set,
		topHeader: 'commonPageHeading',
		tableHeightClass: 'aNfTg',
		topNav:
			pathname === '/insights/cmo/cohort' ||
			pathname === VARIABLE_INSIGHTS_CMO_PERFORMANCE_REPORT ||
			pathname === VARIABLE_INSIGHTS_CHANNEL_PAID_MEDIA_TRACKER
				? null
				: 'top-div',
		cmoHeader: 'Sidebar__appbar',
	});

	const {
		capturePage,
		paidCampanpaignCapture,
		applyModifications,
		capturePageCmoDrill,
	} = usePdfModifier(modifications);
	const selectedColumnForPdf = useSelector(
		(state: any) => state?.selectedColumnForPdf?.selectedArray
	);
	const selectedKey = useSelector(
		(state: any) => state?.selectedColumnForPdf?.key
	);
	const pathSameDrillDownValue = useSelector(
		(state: any) => state?.pathSameDrillDown
	);
	const drillDownCsvHideValue = useSelector(
		(state: any) => state?.drillDownCsvHideValue
	);
	const isTableDataForPdf = useSelector(
		(state: any) => state?.isTableDataForPdf
	);
	let [saveColumns, setSaveColumns]: any = useState(selectedColumnForPdf || []);
	const handleCSV = (link: any) => {
		let tempLink = document.createElement('a');
		tempLink.href = link;
		tempLink.click();
	};
	const url: any = window?.location?.href;
	const cmoDrilldowncheck: any = url?.includes('total-spent');
	const pmtcheck: any = url?.includes('paid-media-tracker');
	const paidCampaignTrackercheck: any = url?.includes('paid-campaign-tracker');
	const pdfNameObj: any = {
		'/insights/channel/path-analysis': 'user-path-analysis',
		[VARIABLE_INSIGHTS_MO_PATH_LENGTH]: 'path-length',
		[VARIABLE_INSIGHTS_CHANNEL_TOP_CONVERSION]: 'top-conversion',
		[VARIABLE_INSIGHTS_CMO_DASHBOARD]: 'cmo-dashboard',
		[VARIABLE_INSIGHTS_TOTAL_SPENT]: 'cmo-drilldown',
		[VARIABLE_INSIGHTS_CHANNEL_HUBSPOT_REPORT]: 'email-marketing',
		[VARIABLE_INSIGHTS_CMO_PERFORMANCE_REPORT]: 'performance-report',
		[VARIABLE_INSIGHTS_MO_MODEL_COMPARISON]: 'model-comparison',
		[VARIABLE_INSIGHTS_CHANNEL_PAID_MEDIA_TRACKER]: 'paid-media-tracker',
		[VARIABLE_INSIGHTS_ABM_COMPARISON_DASHBOARD]: 'new-abm-report',
	};
	const handleCSVDownload = async () => {
		const headers: any = { 'access-token': userData['access-token'] };
		const request: any = {};
		request.linking_type = 0;
		request.format_key = 0;
		request.dashboard_id = id;
		request.report_name = name;
		request.payload = { ...shareReportsRequest };
		const reqBody: any = {
			headers: headers,
			request: request,
		};
		loader.showLoader();
		await APIService.post(ShareReportsEndpoints.downloadCSV(reqBody))
			.then((res: any) => {
				if (name == 'Performance') {
					res?.data?.forEach((data: any, index: number) => {
						setTimeout(() => {
							handleCSV(data);
						}, 1000 * (index + 1));
					});
				} else {
					const tempLink = document.createElement('a');
					tempLink.href = res.data;
					tempLink.click();
				}
			})
			.catch((err: any) => {
				toaster.addToast({
					type: 'error',
					message: err.message,
					timeout: 3000,
				});
			})
			.finally(() => {
				loader.hideLoader();
			});
	};

	const downloadPDFforReports = async () => {
		let gridElement: any = document.getElementsByClassName(
			`${downloadPDFClassName}`
		)[0];
		loader.showLoader();
		savePDF(gridElement, {
			paperSize: 'A4',
			scale:
				name == 'Performance' ||
				name == 'Social Media' ||
				// name == 'CMO' ||
				name == 'Sales Cycle'
					? 0.4
					: 0.3,
			title: `${name} Report`,
			repeatHeaders: false,
			fileName: name,
			landscape: false,
			margin: 0,
			forcePageBreak: '.page-break',
			keepTogether: '.keep-together',
			pageTemplate: pageTemplate,
		});
		setTimeout(() => {
			loader.hideLoader();
		}, 2000);
	};

	const scrollPage = () => {
		// window.scrollTo(100, 5000);
		// const element = document.getElementsByClassName('marginForFixedHeading')[0];
		// element.scrollTop = 800;
	};

	function generatePDFFromBlobs(blobs: Blob[], pageWidth: number) {
		// Create a new jsPDF instance
		var pdf = new jsPDF({
			unit: 'px',
			format: [pageWidth, 841], // Standard A4 height
		});
		// Define constants for positioning and dimensions
		const margin = 0; // Set margin to 0 for no margins
		// const pageWidth = 1 * pdf.internal.pageSize.getWidth(); // Double the page width

		// Function to add image to PDF
		function addImageToPDF(base64data: string, index: number) {
			return new Promise<void>((resolve, reject) => {
				var img = new Image();
				img.onload = function () {
					// Set the width and height to match the page dimensions
					const width = pageWidth - 1 * margin; // Adjusted width
					const height = pdf.internal.pageSize.getHeight() * margin; // Use original height
					// Add image to PDF, positioned at the margins
					pdf.addImage(base64data, 'PNG', margin, margin, width, height);
					if (index < blobs.length - 1) {
						pdf.addPage(); // Add a new page for each image except the last one
					} else {
						// Save the PDF once all images are added
						pdf.save('screenshots.pdf');
					}
					resolve();
				};
				img.onerror = function () {
					reject(new Error('Failed to load image.'));
				};
				img.src = base64data;
			});
		}

		// Loop through each blob and add it as an image to the PDF
		blobs.forEach((blob, index) => {
			var reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = function () {
				var base64data = reader.result as string;
				addImageToPDF(base64data, index);
			};
		});
	}

	function getBlobs(screenshots: HTMLCanvasElement[]) {
		return screenshots.map(function (screenshot) {
			var dataURI = screenshot.toDataURL();

			// convert base64 to raw binary data held in a string
			// doesn't handle URLEncoded DataURIs
			var byteString = atob(dataURI.split(',')[1]);

			// separate out the mime component
			var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

			// write the bytes of the string to an ArrayBuffer
			var ab = new ArrayBuffer(byteString.length);
			var ia = new Uint8Array(ab);
			for (var i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}

			// create a blob for writing to a file
			var blob = new Blob([ab], { type: mimeString });
			return blob;
		});
	}

	function generatePdf() {
		let jsPdf = new jsPDF('p', 'pt', 'letter');
		var htmlElement: any = document.getElementsByClassName(
			'marginForFixedHeading'
		)[0];
		// you need to load html2canvas (and dompurify if you pass a string to html)
		const opt: any = {
			callback: function (jsPdf: any) {
				jsPdf.save('Test.pdf');
				// to open the generated PDF in browser window
				// window.open(jsPdf.output('bloburl'));
			},
			margin: [1, 1, 1, 1],
			autoPaging: 'text',
			html2canvas: {
				allowTaint: true,
				dpi: 300,
				letterRendering: true,
				logging: false,
				scale: 1,
				width: 850,
				height: 841,
			},
		};

		jsPdf.html(htmlElement, opt);
	}

	function PrintAll(screenshots: any) {
		var pages: any = [screenshots];

		var printNext = function (i?: any) {
			i = i || 0;
			if (i >= pages.length) {
				return;
			}

			var wdw: any = window.open(pages[i], 'print');
			wdw.onload = function () {
				wdw.print();

				wdw.close();
				setTimeout(function () {
					printNext(++i);
				}, 100);
			};
		};

		printNext();
	}

	function createPdf() {
		closePopup();
		setTimeout(() => {
			var saveInnerHTML = document.body.innerHTML;
			var actContents = document.getElementsByClassName(
				'marginForFixedHeading'
			)[0].innerHTML;
			document.body.innerHTML = actContents;
			window.print();
			document.body.innerHTML = saveInnerHTML;
			// PrintAll()
		}, 1000);
	}

	const hitAPIforCSV = async () => {
		let csvUrl = '';
		const reqData = {
			payload: {
				...tablePayload,
			},
			component_id: cmoDrilldowncheck ? '15' : '13',
			total_count: count,
			pdf_true: 1,
		};
		return await APIService.post(DynamicTableEndPoint.getCSV(reqData), true)
			.then((res: any) => {
				csvUrl = res?.data?.export_url;
				if (pathname === VARIABLE_INSIGHTS_CHANNEL_PAID_CAMPAIGN_TRACKER) {
					paidCampanpaignCapture(pathname, res?.data?.export_url);
					// capturePageCmoDrill(
					// 	pathname,
					// 	'Paid-Campaign-Tracker',
					// 	res?.data?.export_url
					// );
				}
				useLocalStorage.setItem('csvLink', res?.data?.export_url);
				return csvUrl;
			})
			.catch((err: any) => {
				toaster.addToast({
					message: err?.message || 'Something went wrong',
					timeout: 2000,
					type: 'error',
				});
			});
	};

	async function printContent() {
		let currentUrl = window.location.href;
		let pdfUrl;
		if (currentUrl.includes('/channel/paid-campaign-tracker')) {
			toaster.addToast({
				message: 'Select Columns you want to see in PDF',
				timeout: 2000,
				type: 'error',
			});
		} else {
			closePopup();

			if (currentUrl.includes('/insights/mo/metrics/hub')) {
				pdfUrl = currentUrl.replace(
					'/insights/mo/metrics/hub',
					'/insights/mo/metrics/hub/pdf'
				);
			}
			// Open the PDF URL in a new tab
			if (pdfUrl) {
				window.open(pdfUrl);
			} else {
				console.error('Unsupported URL:', currentUrl);
			}
		}
	}

	function checkReport() {
		var currentUrl = window.location.href;
		if (
			pathname === VARIABLE_INSIGHTS_MO_METRICS_HUB ||
			pathname === VARIABLE_INSIGHTS_CMO_COHORT ||
			pathname === VARIABLE_INSIGHTS_CHANNEL_SEARCH_CONSOLE_REPORT ||
			pathname === VARIABLE_INSIGHTS_CHANNEL_PAID_CAMPAIGN_TRACKER ||
			pathname === VARIABLE_INSIGHTS_CHANNEL_PATH_ANALYSIS ||
			pathname === VARIABLE_INSIGHTS_MO_PATH_LENGTH ||
			pathname === VARIABLE_INSIGHTS_CHANNEL_TOP_CONVERSION ||
			pathname === VARIABLE_INSIGHTS_CMO_DASHBOARD ||
			pathname === VARIABLE_INSIGHTS_TOTAL_SPENT ||
			pathname === VARIABLE_INSIGHTS_CHANNEL_HUBSPOT_REPORT ||
			pathname === VARIABLE_INSIGHTS_CMO_PERFORMANCE_REPORT ||
			pathname === VARIABLE_INSIGHTS_CHANNEL_PAID_MEDIA_TRACKER ||
			pathname === VARIABLE_INSIGHTS_ABM_COMPARISON_DASHBOARD
		) {
			handleClick();
		} else if (pathname === VARIABLE_INSIGHTS_MO_MODEL_COMPARISON) {
			setShowSidebar(false);
			closePopup();
			dispatch(downloadPdf(true));
			setTimeout(async () => {
				await capturePage(pathname, pdfNameObj[pathname]);
			}, 1000);
		} else {
			downloadPDFforReports();
		}
	}

	const postpdfColumns = async (value: string) => {
		try {
			const newVlaue = value?.split('/');
			let modifiedString = newVlaue
				?.filter((item, index) => index !== 0)
				.join('_');
			const headers = {};
			const reqData: any = {
				request: {
					report_name: selectedKey,
					filters: selectedColumnForPdf,
				},
				headers,
			};
			const pdfColumnsApi: any = await APIService.post(
				DynamicTableEndPoint.postPdfColumns(reqData),
				true
			);
		} catch (error) {
			console.log('post pdf api errorr', error);
		}
	};
	const isObjectEmpty = (objectName: object = {}) => {
		return Object.keys(objectName)?.length !== 0;
	};
	const handleClick = async () => {
		const localsaveArray = selcetedColumnsforpct?.map(
			(item: any) => item?.value
		);
		const changeSaveArray = selectedColumnForPdf?.map(
			(item: any) => item?.value
		);

		// await dispatch(downloadPdf(true));
		var currentUrl = window.location.href;

		if (
			currentUrl.includes('/channel/paid-campaign-tracker') ||
			pathname === VARIABLE_INSIGHTS_TOTAL_SPENT
			//  || (pathname === VARIABLE_INSIGHTS_CHANNEL_HUBSPOT_REPORT &&
			// 	pathSameDrillDownValue)
		) {
			if (!isObjectEmpty(isTableDataForPdf)) {
				toaster.addToast({
					message: 'Please wait the data is still loading',
					timeout: 2000,
					type: 'error',
				});
				return;
			}
			if (
				currentUrl.includes('/channel/paid-campaign-tracker') ||
				pathname === VARIABLE_INSIGHTS_TOTAL_SPENT
				// || (pathname === VARIABLE_INSIGHTS_CHANNEL_HUBSPOT_REPORT &&
				// 	pathSameDrillDownValue)
			) {
				if (
					selectedColumnForPdf === undefined ||
					selectedColumnForPdf === null
				) {
					toaster.addToast({
						message: 'Select Columns you want to see in PDF',
						timeout: 2000,
						type: 'error',
					});
					return;
				} else if (selectedColumnForPdf?.length < 5) {
					toaster.addToast({
						message: 'Please select atleast 5 columns',
						timeout: 2000,
						type: 'error',
					});
					return;
				}
				loader.showLoader();
				closePopup();
				await dispatch(downloadPdf(true));
				if (!ignoreOrderCompare(localsaveArray, changeSaveArray)) {
					postpdfColumns(pathname);
				}
				var currentUrl = window.location.href;
				if (
					currentUrl.includes('/metrics/hub') ||
					currentUrl.includes('/channel/paid-campaign-tracker')
				) {
					try {
						loader.showLoader();
						loader.hideLoader();
						closePopup();
						await dispatch(downloadPdf(true));
						if (currentUrl.includes('/channel/paid-campaign-tracker')) {
							await hitAPIforCSV();
							return;
						}
					} catch (error) {
						console.error('Error:', error);
					}
				} else if (
					pathname === VARIABLE_INSIGHTS_TOTAL_SPENT
					//  || (pathname === VARIABLE_INSIGHTS_CHANNEL_HUBSPOT_REPORT &&
					// 	pathSameDrillDownValue)
				) {
					applyModifications();
					setTimeout(async () => {
						// await capturePage(pathname, pdfNameObj[pathname]);
						const getUrl = await hitAPIforCSV();
						capturePageCmoDrill(pathname, pdfNameObj[pathname], getUrl);
					}, 12000);
				}
			}
		} else {
			if (pathname === '/insights/channel/path-analysis') {
				await dispatch(downloadPdf(true));
				closePopup();
				applyModifications();
				// loader.showLoader();
				setTimeout(async () => {
					await capturePage(pathname, pdfNameObj[pathname]);
				}, 12000);
			} else {
				await dispatch(downloadPdf(true));
				closePopup();
				applyModifications();
				// loader.showLoader();
				setTimeout(async () => {
					await capturePage(pathname, pdfNameObj[pathname]);
				}, 12000);

				// downloadPDFforReports();
			}
		}
	};
	const getListFunc = (value: any) => {
		// this is overall list in dropdown
		const newList: any = {
			closed_won: cmoclosedWonColumn,
			closed_marketing_influenced: cmoclosedWonColumn,
			closed_marketing_sourced: cmoclosedWonColumn,

			total_pipeline: cmoPipelineColumn,
			current_pipeline: cmoPipelineColumn,
			total_pipeline_marketing_influenced: cmoPipelineColumn,
			total_pipeline_marketing_sourced: cmoPipelineColumn,
			current_pipeline_marketing_sourced: cmoPipelineColumn, // 14 columns
			current_pipeline_marketing_influenced: cmoPipelineColumn,
			// paidCampaignTracker: defaultColumns,
			paidCampaignTracker: columns,
			'hubspot-report-accountPerformance': accountPerformanceColumns,
		};
		return newList[value];
	};
	const listItemsForDropdown = getListFunc(selectedKey);

	return (
		<Grid xs={12} container className='shareByDownload'>
			<div id='myMm' style={{ height: '1mm' }} />
			<iframe id='printFrame' style={{ display: 'none' }}></iframe>
			<form style={{ width: '100%' }} className='shareByEmail'>
				<Grid
					xs={10}
					display={
						paidCampaignTrackercheck ||
						pathname === VARIABLE_INSIGHTS_TOTAL_SPENT ||
						(pathname === VARIABLE_INSIGHTS_CHANNEL_HUBSPOT_REPORT &&
							pathSameDrillDownValue) // pathSameDrillDownValue , is use beacause when have same path but drill down , so to show only multiselect in drill down  we are using pathSameDrillDownValue key
							? 'none'
							: 'flex'
					}
					gap={'20px'}
					className='first_grid'
				>
					<div
						className='downloadButton'
						style={{ border: `1px solid #bebebe`, cursor: 'pointer' }}
						onClick={checkReport}
					>
						<VerticalAlignBottomOutlined color='primary' />
						<div>PDF</div>
					</div>
					{/* <Button
						variant='outlined'
						type='button'
						btnText='PDF'
						startIcon={<Download />}
						onClick={() => {
							downloadPDFforReports();
						}}
					/> */}
					{includeCSV.includes(name) && (
						<div
							className='downloadButton'
							style={{
								border: `1px solid #bebebe`,
								cursor: 'pointer',
								width: '150px',
								display:
									pmtcheck ||
									(pathname === VARIABLE_INSIGHTS_CHANNEL_HUBSPOT_REPORT &&
										tabValue === 0) ||
									drillDownCsvHideValue ||
									pathname === VARIABLE_INSIGHTS_CMO_PERFORMANCE_REPORT
										? 'none'
										: '',
							}}
							onClick={() => {
								pathname === VARIABLE_INSIGHTS_CHANNEL_HUBSPOT_REPORT
									? csvDownloadFunc()
									: handleCSVDownload();
								closePopup();
							}}
						>
							<VerticalAlignBottomOutlined color='primary' />
							<div style={{ whiteSpace: 'nowrap' }}>
								CSV{' '}
								{pathname === VARIABLE_INSIGHTS_CHANNEL_HUBSPOT_REPORT
									? '/ EXCEL'
									: ''}
							</div>
						</div>
					)}
				</Grid>
				{/* if not paid campaign tracker ------------------------------------------------ */}
				<Grid
					xs={10}
					display={
						paidCampaignTrackercheck ||
						pathname === VARIABLE_INSIGHTS_TOTAL_SPENT ||
						(pathname === VARIABLE_INSIGHTS_CHANNEL_HUBSPOT_REPORT &&
							pathSameDrillDownValue)
							? 'contents'
							: 'none'
					}
					gap={'20px'}
					// style={{display: 'contents'}}
				>
					<div style={{ display: 'flex', gap: '2rem' }}>
						<div
							className='downloadButton'
							style={{ border: `1px solid #bebebe`, cursor: 'pointer' }}
							onClick={checkReport}
						>
							<VerticalAlignBottomOutlined color='primary' />
							<div>PDF</div>
						</div>
						<div
							className='PDFColumns'
							style={{
								display:
									paidCampaignTrackercheck ||
									pathname === VARIABLE_INSIGHTS_TOTAL_SPENT ||
									(pathname === VARIABLE_INSIGHTS_CHANNEL_HUBSPOT_REPORT &&
										pathSameDrillDownValue)
										? 'flex'
										: 'none',
								alignItems: 'center',
								maxWidth: '10rem',
							}}
						>
							{(paidCampaignTrackercheck ||
								pathname === VARIABLE_INSIGHTS_TOTAL_SPENT ||
								(pathname === VARIABLE_INSIGHTS_CHANNEL_HUBSPOT_REPORT &&
									pathSameDrillDownValue)) && (
								<MultiSelectDropdown
									placeholderText={'Select the Columns'}
									// itemList={columns}
									itemList={listItemsForDropdown}
									valueSelector={'value'}
									onChange={(evt: any) => {
										const values: any = [];
										evt.map((event: any) => {
											if (values?.length < 7) {
												values.push(event);
											}
										});
										// saveColumns = values;
										const setDefaultColumnObj = {
											key: selectedKey,
											selectedArray: values,
										};
										dispatch(setPdfColumnsAction(setDefaultColumnObj));
										setSaveColumns(values);
										if (evt?.length > 7) {
											toaster.addToast({
												message: 'You can view maximum 7 Columns at a time.',
												timeout: 2000,
												type: 'error',
											});
										}
									}}
									width='10rem'
									hasSelectAll={false}
									selected={saveColumns}
									// disabled={saveColumns?.length >= 7}
								/>
							)}
						</div>
						<div
							style={{ position: 'absolute', right: '10rem', top: '6.5rem' }}
						>
							<Tooltip title='You can select min. 5 and max. 7 columns at a time when exporting the PDF'>
								<InfoOutlinedIcon fontSize='small' color='info' />
							</Tooltip>
						</div>
					</div>
					<div>
						{includeCSV.includes(name) && (
							<div
								className='downloadButton'
								style={{
									border: `1px solid #bebebe`,
									cursor: 'pointer',
									width: '150px',
									display:
										pmtcheck ||
										(pathname === VARIABLE_INSIGHTS_CHANNEL_HUBSPOT_REPORT &&
											tabValue === 0) ||
										drillDownCsvHideValue ||
										pathname === VARIABLE_INSIGHTS_CMO_PERFORMANCE_REPORT
											? 'none'
											: '',
								}}
								onClick={() => {
									pathname === VARIABLE_INSIGHTS_CHANNEL_HUBSPOT_REPORT
										? csvDownloadFunc()
										: handleCSVDownload();
								}}
							>
								<VerticalAlignBottomOutlined color='primary' />
								<div style={{ whiteSpace: 'nowrap' }}>
									CSV{' '}
									{pathname === VARIABLE_INSIGHTS_CHANNEL_HUBSPOT_REPORT
										? '/ EXCEL'
										: ''}
								</div>
							</div>
						)}
					</div>
				</Grid>
				<Grid
					xs={12}
					textAlign={'center'}
					display={'flex'}
					alignItems={'end'}
					justifyContent={'center'}
				>
					{/* <Button
						type='submit'
						btnText='Download Report'
						style={{ width: '30%' }}
					/> */}

					<Button
						variant={paidCampaignTrackercheck ? 'contained' : 'outlined'}
						type='button'
						btnText='Cancel'
						style={{ width: '30%' }}
						onClick={() => {
							closePopup();
						}}
					/>
				</Grid>
			</form>
		</Grid>
	);
}

export default ShareThroughDownload;

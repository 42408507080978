import {
	SET_CMO_CLOSED_WON_DATA,
	SET_CMO_REQUEST_DATA,
	SET_CMO_GRAPH_DATA,
	SET_CMO_REQUEST_BODY,
	SET_CMO_PIPELINE_API_DATA,
	SET_CMO_METRIC_VALUE,
	RESET_TO_INTIAL_STATE,
	SET_CMO_DIMENSION_VALUE,
	SET_CMO_BOOKMARK_REQUEST_BODY_DATA,
	PDF_CLICKED,
	PDF_COLUMNS,
	TABLEDATA_FOR_PDF,
	ROWS_COUNT_PDF,
	PATH_SAME_DRILL_DOWN,
	DRILL_DOWN_CSV_HIDE_OPTION,
	GLOBAL_FILTER_DATA,
	CMO_CSV_PAYLOAD,
} from '../actionTypes/actionType';

const setCmoRequestData = (data) => {
	return {
		type: SET_CMO_REQUEST_DATA,
		data: data,
	};
};
const setClosedWonApiData = (data) => {
	return {
		type: SET_CMO_CLOSED_WON_DATA,
		data: data,
	};
};
const setCmoPipelineApiData = (data) => {
	return {
		type: SET_CMO_PIPELINE_API_DATA,
		data: data,
	};
};
const setCmoGraphData = (data) => {
	return {
		type: SET_CMO_GRAPH_DATA,
		data: data,
	};
};
const setCmoRequestBody = (data) => {
	return {
		type: SET_CMO_REQUEST_BODY,
		data: data,
	};
};
const setCmoBookmarkRequest = (data) => {
	return {
		type: SET_CMO_BOOKMARK_REQUEST_BODY_DATA,
		data: data,
	};
};

const setCmoMetricValue = (data) => {
	return {
		type: SET_CMO_METRIC_VALUE,
		data: data,
	};
};
const setCmoDimensionValue = (data) => {
	return {
		type: SET_CMO_DIMENSION_VALUE,
		data: data,
	};
};

const resetToInitialState = (data) => {
	return {
		type: RESET_TO_INTIAL_STATE,
		data: data,
	};
};

const downloadPdf = (data) => {
	return {
		type: PDF_CLICKED,
		data: data,
	};
};
const drilldownAction = (data) => {
	return {
		type: PATH_SAME_DRILL_DOWN,
		data: data,
	};
};
const drilldownToggleCsvAction = (data) => {
	return {
		type: DRILL_DOWN_CSV_HIDE_OPTION,
		data: data,
	};
};
const setPdfColumnsAction = (data) => {
	return {
		type: PDF_COLUMNS,
		data: data,
	};
};
const setTableObjAction = (data) => {
	return {
		type: TABLEDATA_FOR_PDF,
		data: data,
	};
};
const rowCountPdfAction = (data) => {
	return {
		type: ROWS_COUNT_PDF,
		data: data,
	};
};

const globalFilterAction = (data) => {
	return {
		type: GLOBAL_FILTER_DATA,
		data: data,
	};
};
const cmoCsvPayloadAction = (data) => {
	return {
		type: CMO_CSV_PAYLOAD,
		data: data,
	};
};

export {
	setCmoRequestData,
	setClosedWonApiData,
	setCmoGraphData,
	setCmoMetricValue,
	resetToInitialState,
	setCmoDimensionValue,
	setCmoBookmarkRequest,
	setCmoPipelineApiData,
	setCmoRequestBody,
	downloadPdf,
	setPdfColumnsAction,
	setTableObjAction,
	rowCountPdfAction,
	drilldownAction,
	drilldownToggleCsvAction,
	globalFilterAction,
	cmoCsvPayloadAction,
};

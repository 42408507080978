import { styled } from '@mui/system';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { DateTimeFormatter } from '../../../utils/DateFormatter';
import { FilterData } from '../../../constants/filterData';
import { FilterDataNode } from '../../../constants/newFilterData';
import { channelPerformanceFilterData } from '../../../constants/channelPerformanceFilterData';

export const InsightsBreadCrumbs = [
	{
		name: 'CMO',
		disabled: true,
	},
	{
		name: 'CMO Dashboard',
		disabled: true,
	},
];

export const InsightsTabsList = styled(TabsListUnstyled)`
	width: 100%;
	display: flex;
	margin-top: 2rem;
	align-items: center;
	justify-content: start;
	align-content: space-between;
	// font-size: 14px;
`;

export const MDMetricsList = [
	{ name: 'Revenue', value: 6 },
	{ name: 'Spend', value: 1 },
	{ name: 'Total Closed Won', value: 5 },
	{ name: 'Current Pipeline', value: 4 },
	{ name: 'Total Pipeline', value: 3 },
	{ name: 'Current Opportunity', value: 7 },
	{ name: 'Total Opportunities', value: 8 },
	{ name: 'Contacts', value: 9 },
	{ name: 'Leads', value: 2 },
	{ name: 'Sessions', value: 10 },
	{ name: 'Unique Visitors', value: 11 },
	{ name: 'Form Filled', value: 12 },
];

export const SocialMediaMatrices = [
	{ name: 'Revenue', value: 6 },
	{ name: 'Spend', value: 1 },
	{ name: 'Total Closed Won', value: 5 },
	{ name: 'Current Pipeline', value: 4 },
	{ name: 'Total Pipeline', value: 3 },
	{ name: 'Total Opportunities', value: 8 },
	{ name: 'Contacts', value: 9 },
	{ name: 'Leads', value: 2 },
	{ name: 'Sessions', value: 10 },
	{ name: 'Unique Visitors', value: 11 },
	{ name: 'Form Filled', value: 12 },
];

export const MDDimensionsList = [
	{ name: 'Channel', value: 1 },
	{ name: 'Source', value: 2 },
	{ name: 'Campaign', value: 3 },
	{ name: 'Ad Group', value: 4 },
	{ name: 'Keyword', value: 6 },
];

export const PaidCampaignDimension = [
	{ name: 'Channel', value: 1 },
	{ name: 'Source', value: 2 },
	{ name: 'Campaign', value: 3 },
	{ name: 'Ad Group', value: 4 },
	{ name: 'Keyword', value: 6 },
];

export class InsightsData {
	static getDefaultChannelFilters() {
		return {
			date_type: '',
			attribution_model: 1,
			start_date: '',
			end_date: '',
			segment_id: null,
			conditions: [],
			filters: { condition: [] },
			isReload: true,
		};
	}
	static getDefaultFilters() {
		return {
			date_type: '',
			attribution_model: 1,
			start_date: '',
			end_date: '',
			segment_id: null,
			conditions: [],
			filters: { condition: [] },
			isReload: true,
		};
	}
	static getDefaultCMOFilters() {
		return {
			date_type: '',
			attribution_model: 1,
			relational_operator: 1,
			start_date: '',
			end_date: '',
			segment_id: null,
			conditions: [],
			excludes: [],
			filters: { condition: [] },
			isReload: true,
		};
	}
	static getDefaultChannelFiltersPerformance() {
		return {
			date_type: '',
			attribution_model: 1,
			relational_operator: 1,
			start_date: '',
			end_date: '',
			segment_id: null,
			conditions: [],
			excludes: [],
			filters: { condition: [] },
			isReload: true,
		};
	}
	static getDefaultFiltersforPaidCampaign() {
		return {
			date_type: 1,
			attribution_model: 1,
			start_date: '',
			end_date: '',
			segment_id: null,
			conditions: [],
			filters: { condition: [] },
			isReload: true,
		};
	}
	static getDefaultFiltersforSMD() {
		return {
			date_type: '',
			attribution_model: 1,
			start_date: DateTimeFormatter.getPastValueFromToday(6),
			end_date: DateTimeFormatter.getCurrentDate(),
			segment_id: '',
			conditions: [],
			connector: 1,
		};
	}

	static getDefaultRange() {
		return {
			start_date: DateTimeFormatter.getPastValueFromToday(6),
			end_date: DateTimeFormatter.getCurrentDate(),
			pre_end_date: DateTimeFormatter.getPastValueFromToday(1),
			pre_start_date: DateTimeFormatter.getPastValueFromToday(2),
		};
	}

	static getFilters() {
		return [
			{
				name: 'Date Type',
				data: FilterData.getDateType(),
				type: 'dropdown',
				id: 'date_type',
			},
			{
				name: 'Attribution Model',
				data: FilterData.getAttributionModel(),
				type: 'dropdown',
				id: 'attribution_model',
				required: true,
			},
			{
				name: 'Dimensions',
				type: 'conditional',
				id: 'conditions',
				parameters: FilterData.getDimensionParameter(),
				operators: FilterData.getOperatorList(),
			},
		];
	}

	static getFiltersforCMO() {
		return [
			{
				name: 'Date Type',
				data: FilterDataNode.getDateType(),
				type: 'dropdown',
				id: 'date_type',
				required: false,
			},
			{
				name: 'Attribution Model',
				data: FilterDataNode.getAttributionModel(),
				type: 'dropdown',
				id: 'attribution_model',
				required: true,
			},
			{
				name: 'Dimensions',
				type: 'conditional',
				id: 'conditions',
				parameters: FilterDataNode.getDimensionParameter(),
				operators: FilterDataNode.getOperatorList(),
			},
		];
	}

	static getFiltersforPerformance() {
		return [
			{
				name: 'Date Type',
				data: FilterDataNode.getDateType(),
				type: 'dropdown',
				id: 'date_type',
				required: false,
			},
			{
				name: 'Attribution Model',
				data: FilterDataNode.getAttributionModel(),
				type: 'dropdown',
				id: 'attribution_model',
				required: true,
			},
			{
				name: 'Filter Clauses',
				type: 'conditional',
				id: 'conditions',
				parameters: FilterDataNode.getDimensionParameter(),
				operators: FilterDataNode.getOperatorList(),
			},
		];
	}

	static getFiltersforPCT() {
		return [
			{
				name: 'Date Type',
				data: FilterDataNode.getDateType(),
				type: 'dropdown',
				id: 'date_type',
				required: false,
			},
			{
				name: 'Attribution Model',
				data: FilterDataNode.getAttributionModelforPCT(),
				type: 'dropdown',
				id: 'attribution_model',
				required: true,
			},
			{
				name: 'Dimensions',
				type: 'conditional',
				id: 'conditions',
				parameters: FilterDataNode.getDimensionParameterforPCt(),
				// operators: FilterDataNode.getOperatorList(),
				operators: FilterData.getOperatorListForTopConversion(),
			},
		];
	}

	static getFiltersForSmd() {
		return [
			// {
			// 	name: 'Date Type',
			// 	data: FilterData.getDateType(),
			// 	type: 'dropdown',
			// 	id: 'date_type',
			// 	required: false,
			// },
			{
				name: 'Attribution Model',
				data: FilterDataNode.getAttributionModel(),
				type: 'dropdown',
				id: 'attribution_model',
				required: true,
			},
			{
				name: 'Connectors',
				data: FilterDataNode.getConnectors(),
				type: 'dropdown',
				id: 'connector',
				required: true,
			},
			// {
			// 	name: 'Dimensions',
			// 	type: 'conditional',
			// 	id: 'conditions',
			// 	parameters: FilterData.getDimensionParameter().slice(0, 2),
			// 	operators: FilterData.getOperatorList(),
			// },
		];
	}

	static getFiltersForCPdrilldown() {
		return [
			{
				name: 'Date Type',
				data: channelPerformanceFilterData.getDateType(),
				type: 'dropdown',
				id: 'date_type',
				required: true,
			},
			{
				name: 'Attribution Model',
				data: channelPerformanceFilterData.getAttributionModel(),
				type: 'dropdown',
				id: 'attribution_model',
				required: true,
			},
			{
				name: 'Dimensions',
				type: 'conditional',
				id: 'conditions',
				parameters: channelPerformanceFilterData.getDimensionParameter(),
				operators: channelPerformanceFilterData.getOperatorList(),
			},
		];
	}
}

export interface InsightsResponse {}

export interface IMDVennDetails {
	box_values: IMDBoxValues[];
	venn_data: IMDBoxValues[];
	summary: IMDVennSummary[];
}
export interface IMDCardDetails {
	overall: {
		value: number;
		change: number;
	};
	influenced: {
		value: number;
		change: number;
	};
	sourced: {
		value: number;
		change: number;
	};
}

interface IMDBoxValues {
	name: string;
	value: number;
	change: number;
}

interface IMDVennSummary {
	total: {
		value: number;
		change: number;
	};
	roi: {
		value: number;
		change: number;
	};
	oppts: {
		value: number;
		change: number;
	};
	cpo: {
		value: number;
		change: number;
	};
	options?: {
		label?: string;
		color?: string;
	};
}

export const defaultTrendsDateType: any = {
	revenue: {
		label: 'Close Date is applied by default.',
		value: 2,
	},
	spend: {
		label: 'Created Date is applied by default.',
		value: 1,
	},
	deals: {
		label: 'Close Date is applied by default.',
		value: 2,
	},
	pipeline: {
		label: 'Created Date is applied by default.',
		value: 1,
	},
	opportunities: {
		label: 'Created Date is applied by default.',
		value: 1,
	},
	contacts: {
		label: 'Created Date is applied by default.',
		value: 1,
	},
	leads: {
		label: 'Created Date is applied by default.',
		value: 1,
	},
	sessions: {
		label: 'Created Date is applied by default.',
		value: 1,
	},
	unique_visitors: {
		label: 'Created Date is applied by default.',
		value: 1,
	},
	form_filled: {
		label: 'Created Date is applied by default.',
		value: 1,
	},
	current_opportunity: {
		label: 'Created Date is applied by default.',
		value: 1,
	},
	total_pipeline: {
		label: 'Created Date is applied by default.',
		value: 1,
	},
};
export const defaultInsightsDateType = {
	closed_won: {
		label: 'Close Date is applied by default.',
		value: 2,
	},
	pipeline: {
		label: 'Created Date is applied by default.',
		value: 1,
	},
};

export interface IInsightsFilter {
	//change these types to exact what we are getting once all reports are migrated
	date_type: any;
	attribution_model: any;
	start_date: string;
	segment_id?: any;
	end_date: string;
	conditions: any;
	relational_operator?: any;
}

export const CmoClosedWonUrl = 'marketingnode/marketing_reports/closedWon';
export const CmoAnalyticGraphUrl = 'marketingdashboard/analytical_graph_dev';
export const monthsArray = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];
